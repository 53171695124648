<template>
	<modal class="NoxModalNews" name="NoxModalNews" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'editNews'">Редактирование новости</span>
					<span v-else-if="noxType == 'editWidget2'">Редактирование виджета</span>
					<span v-else-if="noxType == 'editWidget3'">Редактирование виджета</span>
					<span v-else-if="noxType == 'editWidget7'">Редактирование виджета</span>
					<span v-else-if="noxType == 'deleteNews'">Удаление новости</span>
				</div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div v-if="noxType == 'editNews'"></div>
					<div v-else-if="noxType == 'editWidget2'">
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Статус виджета:</div>
							<div class="nox_modal_info_row_value">
								<toggle-button class="nox_toggle_button" :value="toggleValue(noxStatus)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange($event, 1)"></toggle-button>
								<div v-html="noxAlertStatus"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Название виджета (RU):</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxNameRU" placeholder="название виджета (ru)" autocomplete="off">
								<div v-html="noxAlertNameRU"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Название виджета (EN):</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxNameEN" placeholder="название виджета (en)" autocomplete="off">
								<div v-html="noxAlertNameEN"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Тема вебинара (RU):</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxData.theme_ru" placeholder="тема вебинара (ru)" autocomplete="off">
								<div v-html="noxAlertDataThemeRU"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Тема вебинара (EN):</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxData.theme_en" placeholder="тема вебинара (en)" autocomplete="off">
								<div v-html="noxAlertDataThemeEN"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Ссылка на вебинар:</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxData.link" placeholder="ссылка на вебинар" autocomplete="off">
								<div v-html="noxAlertDataLink"></div>
							</div>
						</div>
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label">Дата проведения:</div>
							<div class="nox_modal_info_row_value">
								<datetime type="datetime" class="nox_datetime" format="dd.MM.yyyy, HH:mm:ss" :phrases="{ ok: '[Продолжить]', cancel: '[Закрыть]' }" v-model="noxData.datetime" placeholder="дата проведения" auto></datetime>
								<div v-html="noxAlertDataDatetime"></div>
							</div>
						</div>
					</div>
					<div v-else-if="noxType == 'editWidget3'">
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Статус виджета:</div>
							<div class="nox_modal_info_row_value">
								<toggle-button class="nox_toggle_button" :value="toggleValue(noxStatus)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange($event, 1)"></toggle-button>
								<div v-html="noxAlertStatus"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Название виджета (RU):</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxNameRU" placeholder="название виджета (ru)" autocomplete="off">
								<div v-html="noxAlertNameRU"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Название виджета (EN):</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxNameEN" placeholder="название виджета (en)" autocomplete="off">
								<div v-html="noxAlertNameEN"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Текст виджета (RU):</div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea" v-model.trim="noxTextRU" placeholder="текст виджета (ru)"></textarea>
								<div v-html="noxAlertTextRU"></div>
							</div>
						</div>
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label">Текст виджета (EN):</div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea" v-model.trim="noxTextEN" placeholder="текст виджета (en)"></textarea>
								<div v-html="noxAlertTextEN"></div>
							</div>
						</div>
					</div>
					<div v-else-if="noxType == 'editWidget7'">
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Статус виджета:</div>
							<div class="nox_modal_info_row_value">
								<toggle-button class="nox_toggle_button" :value="toggleValue(noxStatus)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange($event, 1)"></toggle-button>
								<div v-html="noxAlertStatus"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Название виджета (RU):</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxNameRU" placeholder="название виджета (ru)" autocomplete="off">
								<div v-html="noxAlertNameRU"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Название виджета (EN):</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxNameEN" placeholder="название виджета (en)" autocomplete="off">
								<div v-html="noxAlertNameEN"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Текст виджета (RU):</div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea" v-model.trim="noxTextRU" placeholder="текст виджета (ru)"></textarea>
								<div v-html="noxAlertTextRU"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Текст виджета (EN):</div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea" v-model.trim="noxTextEN" placeholder="текст виджета (en)"></textarea>
								<div v-html="noxAlertTextEN"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Скрытые символы:</div>
							<div class="nox_modal_info_row_value">
								<div class="nox_checkboxes">
									<label class="nox_checkbox" :for="'nox_checkbox_symbol_' + index" v-for="(value, index) in noxDataHiddenSymbols" :key="index">
										<input type="checkbox" :id="'nox_checkbox_symbol_' + index" v-model.trim="noxDataHiddenSymbolsStates[index]">
										<span class="nox_checkbox_mark"></span>
										<span class="nox_checkbox_text">{{ value }}</span>
									</label>
								</div>
								<div v-html="noxAlertDataHiddenSymbols"></div>
							</div>
						</div>
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label">Скрывать в выходные:</div>
							<div class="nox_modal_info_row_value">
								<toggle-button class="nox_toggle_button" :value="toggleValue(noxData.is_hidden_in_weekends)" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChange($event, 2)"></toggle-button>
								<div v-html="noxAlertDataIsHiddenInWeekends"></div>
							</div>
						</div>
					</div>
					<div v-else-if="noxType == 'deleteNews'">Вы действительно хотите удалить новость?</div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-if="['editNews', 'editWidget2', 'editWidget3', 'editWidget7'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
					<button type="button" class="nox_button common red" @click="axios" v-else-if="noxType == 'deleteNews'" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertStatus: '',
			noxAlertNameRU: '',
			noxAlertNameEN: '',
			noxAlertTextRU: '',
			noxAlertTextEN: '',
			noxAlertDataLink: '',
			noxAlertDataThemeRU: '',
			noxAlertDataThemeEN: '',
			noxAlertDataDatetime: '',
			noxAlertDataHiddenSymbols: '',
			noxAlertDataIsHiddenInWeekends: '',
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxStatus: 0,
			noxNameRU: '',
			noxNameEN: '',
			noxTextRU: '',
			noxTextEN: '',
			noxData: {},
			noxDataHiddenSymbols: [],
			noxDataHiddenSymbolsStates: [],
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxStatus = 0;
				this.noxNameRU = '';
				this.noxNameEN = '';
				this.noxTextRU = '';
				this.noxTextEN = '';
				this.noxData = {};
				this.noxDataHiddenSymbols = [];
				this.noxDataHiddenSymbolsStates = [];
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 'editWidget2') {

					this.noxStatus = this.$parent.noxWidget2.status;
					this.noxNameRU = this.$parent.noxWidget2.name_ru;
					this.noxNameEN = this.$parent.noxWidget2.name_en;
					this.noxTextRU = this.$parent.noxWidget2.text_ru;
					this.noxTextEN = this.$parent.noxWidget2.text_en;
					this.noxData = this.$parent.noxWidget2.data;
				}
				else if (this.noxType == 'editWidget3') {

					this.noxStatus = this.$parent.noxWidget3.status;
					this.noxNameRU = this.$parent.noxWidget3.name_ru;
					this.noxNameEN = this.$parent.noxWidget3.name_en;
					this.noxTextRU = this.$parent.noxWidget3.text_ru;
					this.noxTextEN = this.$parent.noxWidget3.text_en;
					this.noxData = this.$parent.noxWidget3.data;
				}
				else if (this.noxType == 'editWidget7') {

					this.noxStatus = this.$parent.noxWidget7.status;
					this.noxNameRU = this.$parent.noxWidget7.name_ru;
					this.noxNameEN = this.$parent.noxWidget7.name_en;
					this.noxTextRU = this.$parent.noxWidget7.text_ru;
					this.noxTextEN = this.$parent.noxWidget7.text_en;
					this.noxData = this.$parent.noxWidget7.data;

					if (Object.keys(this.noxData.clv_currency_pairs).length) {
						for (var i in this.noxData.clv_currency_pairs) {
							this.noxDataHiddenSymbols.push(i);
							this.noxDataHiddenSymbolsStates.push(this.noxData.hidden_symbols.includes(i) ? true : false);
						}
					}
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertStatus = '';
				this.noxAlertNameRU = '';
				this.noxAlertNameEN = '';
				this.noxAlertTextRU = '';
				this.noxAlertTextEN = '';
				this.noxAlertDataLink = '';
				this.noxAlertDataThemeRU = '';
				this.noxAlertDataThemeEN = '';
				this.noxAlertDataDatetime = '';
				this.noxAlertDataHiddenSymbols = '';
				this.noxAlertDataIsHiddenInWeekends = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать id виджета.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный id виджета.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать статус виджета.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верный статус виджета.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать название виджета (ru).'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верное название виджета (ru).'; }
				else if (i == 7) { this.noxTemp = 'Необходимо указать название виджета (en).'; }
				else if (i == 8) { this.noxTemp = 'Необходимо указать верное название виджета (en).'; }
				else if (i == 9) { this.noxTemp = 'Необходимо указать текст виджета (ru).'; }
				else if (i == 10) { this.noxTemp = 'Необходимо указать верный текст виджета (ru).'; }
				else if (i == 11) { this.noxTemp = 'Необходимо указать текст виджета (en).'; }
				else if (i == 12) { this.noxTemp = 'Необходимо указать верный текст виджета (en).'; }
				else if (i == 13) { this.noxTemp = 'Необходимо указать дополнительные данные виджета.'; }
				else if (i == 14) { this.noxTemp = 'Необходимо указать верные дополнительные данные виджета.'; }
				else if (i == 15) { this.noxTemp = 'Необходимо указать тему вебинара (ru).'; }
				else if (i == 16) { this.noxTemp = 'Необходимо указать верную тему вебинара (ru).'; }
				else if (i == 17) { this.noxTemp = 'Необходимо указать тему вебинара (en).'; }
				else if (i == 18) { this.noxTemp = 'Необходимо указать верную тему вебинара (en).'; }
				else if (i == 19) { this.noxTemp = 'Необходимо указать ссылку на вебинар.'; }
				else if (i == 20) { this.noxTemp = 'Необходимо указать верную ссылку на вебинар.'; }
				else if (i == 21) { this.noxTemp = 'Необходимо указать дату и время проведения вебинара.'; }
				else if (i == 22) { this.noxTemp = 'Необходимо указать верную дату и время проведения вебинара.'; }
				else if (i == 23) { this.noxTemp = 'Необходимо указать данные валютных пар CLV.'; }
				else if (i == 24) { this.noxTemp = 'Необходимо указать верные данные валютных пар CLV.'; }
				else if (i == 25) { this.noxTemp = 'Необходимо выбрать скрытые символы.'; }
				else if (i == 26) { this.noxTemp = 'Необходимо выбрать верные скрытые символы.'; }
				else if (i == 27) { this.noxTemp = 'Необходимо указать состояние переключателя "скрывать в выходные".'; }
				else if (i == 28) { this.noxTemp = 'Необходимо указать верное состояние переключателя "скрывать в выходные".'; }
				else if (i == 29) { this.noxTemp = 'Необходимо указать id новости.'; }
				else if (i == 30) { this.noxTemp = 'Необходимо указать верный id новости.'; }
				else if (i == 31) { this.noxTemp = 'Id виджета не найден в системе.'; }
				else if (i == 32) { this.noxTemp = 'Id новости не найден в системе.'; }
				else              { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (['editWidget2', 'editWidget3', 'editWidget7'].includes(_this.noxType)) {
						if (!_this.noxNameRU) {
							_this.noxAlertNameRU = _this.getError(5);
						}
						else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxNameRU)) {
							_this.noxAlertNameRU = _this.getError(6);
						}
						if (!_this.noxNameEN) {
							_this.noxAlertNameEN = _this.getError(7);
						}
						else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxNameEN)) {
							_this.noxAlertNameEN = _this.getError(8);
						}
						if (_this.noxType == 'editWidget2') {
							if (!_this.noxData.theme_ru) {
								_this.noxAlertDataThemeRU = _this.getError(15);
							}
							else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxData.theme_ru)) {
								_this.noxAlertDataThemeRU = _this.getError(16);
							}
							if (!_this.noxData.theme_en) {
								_this.noxAlertDataThemeEN = _this.getError(17);
							}
							else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxData.theme_en)) {
								_this.noxAlertDataThemeEN = _this.getError(18);
							}
							if (_this.noxData.link && !_this.$store.state.noxRegexAnyLink.test(_this.noxData.link)) {
								_this.noxAlertDataLink = _this.getError(20);
							}
							if (!_this.noxData.datetime) {
								_this.noxAlertDataDatetime = _this.getError(21);
							}
							else if ((new Date(_this.noxData.datetime)) == 'Invalid Date') {
								_this.noxAlertDataDatetime = _this.getError(22);
							}
						}
						else if (_this.noxType == 'editWidget7') {
							_this.noxData.hidden_symbols = [];
							for (var i in _this.noxDataHiddenSymbolsStates) {
								if (_this.noxDataHiddenSymbolsStates[i] === true) { _this.noxData.hidden_symbols.push(_this.noxDataHiddenSymbols[i]); }
							}
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/widgets';
							config.data = { id: _this.noxId, status: _this.noxStatus, name_ru: _this.noxNameRU, name_en: _this.noxNameEN, text_ru: _this.noxTextRU, text_en: _this.noxTextEN, data: JSON.stringify(_this.noxData) };
							config.method = 'patch';
						}
					}
					else if (_this.noxType == 'deleteNews') {
						config.url = '/v2/admin/account/news';
						config.data = { id: _this.noxId };
						config.method = 'delete';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.noxType == 'editWidget2') {
									_this.$parent.noxWidget2.status = _this.noxStatus;
									_this.$parent.noxWidget2.name_ru = _this.noxNameRU;
									_this.$parent.noxWidget2.name_en = _this.noxNameEN;
									_this.$parent.noxWidget2.text_ru = _this.noxTextRU;
									_this.$parent.noxWidget2.text_en = _this.noxTextEN;
									_this.$parent.noxWidget2.data = _this.noxData;
									_this.$parent.initWidget(2, _this.$parent.noxWidget2);
								}
								else if (_this.noxType == 'editWidget3') {
									_this.$parent.noxWidget3.status = _this.noxStatus;
									_this.$parent.noxWidget3.name_ru = _this.noxNameRU;
									_this.$parent.noxWidget3.name_en = _this.noxNameEN;
									_this.$parent.noxWidget3.text_ru = _this.noxTextRU;
									_this.$parent.noxWidget3.text_en = _this.noxTextEN;
									_this.$parent.noxWidget3.data = _this.noxData;
								}
								else if (_this.noxType == 'editWidget7') {
									_this.$parent.noxWidget7.status = _this.noxStatus;
									_this.$parent.noxWidget7.name_ru = _this.noxNameRU;
									_this.$parent.noxWidget7.name_en = _this.noxNameEN;
									_this.$parent.noxWidget7.text_ru = _this.noxTextRU;
									_this.$parent.noxWidget7.text_en = _this.noxTextEN;
									_this.$parent.noxWidget7.data = _this.noxData;
								}
								else if (_this.noxType == 'deleteNews') {
									_this.$parent.noxNewsData.splice(_this.noxIndex, 1);
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['editWidget2', 'editWidget3', 'editWidget7'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'STATUS_IS_EMPTY') { _this.noxAlertStatus = _this.getError(3); }
										else if (data.response.data.error == 'STATUS_NOT_VALID') { _this.noxAlertStatus = _this.getError(4); }
										else if (data.response.data.error == 'NAME_RU_IS_EMPTY') { _this.noxAlertNameRU = _this.getError(5); }
										else if (data.response.data.error == 'NAME_RU_NOT_VALID') { _this.noxAlertNameRU = _this.getError(6); }
										else if (data.response.data.error == 'NAME_EN_IS_EMPTY') { _this.noxAlertNameEN = _this.getError(7); }
										else if (data.response.data.error == 'NAME_EN_NOT_VALID') { _this.noxAlertNameEN = _this.getError(8); }
										else if (data.response.data.error == 'TEXT_RU_IS_EMPTY') { _this.noxAlertTextRU = _this.getError(9); }
										else if (data.response.data.error == 'TEXT_RU_NOT_VALID') { _this.noxAlertTextRU = _this.getError(10); }
										else if (data.response.data.error == 'TEXT_EN_IS_EMPTY') { _this.noxAlertTextEN = _this.getError(11); }
										else if (data.response.data.error == 'TEXT_EN_NOT_VALID') { _this.noxAlertTextEN = _this.getError(12); }
										else if (data.response.data.error == 'DATA_IS_EMPTY') { _this.noxAlert = _this.getError(13); }
										else if (data.response.data.error == 'DATA_NOT_VALID') { _this.noxAlert = _this.getError(14); }
										else if (data.response.data.error == 'DATA_ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'DATA_ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										if (_this.noxType == 'editWidget2') {
											if      (data.response.data.error == 'DATA_THEME_RU_IS_EMPTY') { _this.noxAlertDataThemeRU = _this.getError(15); }
											else if (data.response.data.error == 'DATA_THEME_RU_NOT_VALID') { _this.noxAlertDataThemeRU = _this.getError(16); }
											else if (data.response.data.error == 'DATA_THEME_EN_IS_EMPTY') { _this.noxAlertDataThemeEN = _this.getError(17); }
											else if (data.response.data.error == 'DATA_THEME_EN_NOT_VALID') { _this.noxAlertDataThemeEN = _this.getError(18); }
											else if (data.response.data.error == 'DATA_LINK_IS_EMPTY') { _this.noxAlertDataLink = _this.getError(19); }
											else if (data.response.data.error == 'DATA_LINK_NOT_VALID') { _this.noxAlertDataLink = _this.getError(20); }
											else if (data.response.data.error == 'DATA_DATETIME_IS_EMPTY') { _this.noxAlertDataDatetime = _this.getError(21); }
											else if (data.response.data.error == 'DATA_DATETIME_NOT_VALID') { _this.noxAlertDataDatetime = _this.getError(22); }
										}
										else if (_this.noxType == 'editWidget7') {
											if      (data.response.data.error == 'DATA_CLV_CURRENCY_PAIRS_IS_EMPTY') { _this.noxAlert = _this.getError(23); }
											else if (data.response.data.error == 'DATA_CLV_CURRENCY_PAIRS_NOT_VALID') { _this.noxAlert = _this.getError(24); }
											else if (data.response.data.error == 'DATA_HIDDEN_SYMBOLS_IS_EMPTY') { _this.noxAlertDataHiddenSymbols = _this.getError(25); }
											else if (data.response.data.error == 'DATA_HIDDEN_SYMBOLS_NOT_VALID') { _this.noxAlertDataHiddenSymbols = _this.getError(26); }
											else if (data.response.data.error == 'DATA_IS_HIDDEN_IN_WEEKENDS_IS_EMPTY') { _this.noxAlertDataIsHiddenInWeekends = _this.getError(27); }
											else if (data.response.data.error == 'DATA_IS_HIDDEN_IN_WEEKENDS_NOT_VALID') { _this.noxAlertDataIsHiddenInWeekends = _this.getError(28); }
										}
									}
									else if (_this.noxType == 'deleteNews') {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(29); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(30); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (['editWidget2', 'editWidget3', 'editWidget7'].includes(_this.noxType)) {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(31); }
									}
									else if (_this.noxType == 'deleteNews') {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(32); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChange: function(event, type) {
				if      (type == 1) { this.noxStatus = Number(event.value); }
				else if (type == 2) { this.noxData.is_hidden_in_weekends = Number(event.value); }
			},
			close: function() {
				this.$modal.hide('NoxModalNews');
			}
		}
	}
</script>
